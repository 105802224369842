import React from 'react';
import { graphql } from 'gatsby';
import Seo from 'gatsby-plugin-wpgraphql-seo';
import ComponentRenderer from '../components/componentRenderer/ComponentRenderer';
import PageHeading from '../components/pageHeading';

const Page = props => {
    const {
        data: { wpPage },
        pageContext,
    } = props;
    const { contentItems, title, wpChildren: childNodes, nodeType } = wpPage;

    return (
        <>
            <Seo post={wpPage} />
            {/* <pre>{JSON.stringify(contentItems.pageContent, null, 2)}</pre> */}

            {!contentItems?.hideHeading && (
                <PageHeading
                    title={title}
                    subHeading={contentItems.subHeading}
                    intro={contentItems.intro}
                    standoutHeading={contentItems.standoutHeading}
                    image={contentItems.image}
                    showCheckATrade={contentItems.showCheckATrade}
                />
            )}
            <ComponentRenderer
                components={contentItems.pageContent}
                nodeID={pageContext.id}
                childNodes={childNodes.nodes}
                nodeType={nodeType}
            />
        </>
    );
};

export default Page;

export const pageQuery = graphql`
    query GET_PAGE($id: String!) {
        wpPage(id: { eq: $id }) {
            nodeType
            title
            uri
            ...SeoPageInformation
            wpChildren {
                nodes {
                    ... on WpPage {
                        ...PageEntryFragment
                    }
                }
            }
            contentItems {
                hideHeading
                showCheckATrade
                pageContent {
                    ... on WpPage_Contentitems_PageContent_HeroSlider {
                        buttonText
                        buttonText2
                        buttonLink2 {
                            ... on WpPage {
                                id
                                uri
                            }
                        }
                        buttonLink {
                            ... on WpPage {
                                id
                                uri
                            }
                        }
                        heading
                        fieldGroupName
                        gallery {
                            altText
                            localFile {
                                childImageSharp {
                                    gatsbyImageData(width: 1920, height: 720, quality: 90, layout: CONSTRAINED)
                                }
                            }
                        }
                    }
                    ... on WpPage_Contentitems_PageContent_FeaturedInlineBlocks {
                        fieldGroupName
                        heading
                        intro
                        items {
                            heading
                            link {
                                ... on WpPage {
                                    id
                                    uri
                                }
                            }
                            image {
                                altText
                                localFile {
                                    childImageSharp {
                                        gatsbyImageData(width: 420, height: 320, quality: 90, layout: CONSTRAINED)
                                    }
                                }
                            }
                        }
                    }
                    ... on WpPage_Contentitems_PageContent_FeaturedProjects {
                        fieldGroupName
                        heading
                        intro
                        items {
                            ... on WpProject {
                                id
                                uri
                                title
                                featuredImage {
                                    node {
                                        altText
                                        localFile {
                                            childImageSharp {
                                                gatsbyImageData(
                                                    width: 620
                                                    height: 480
                                                    quality: 90
                                                    layout: CONSTRAINED
                                                )
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                    ... on WpPage_Contentitems_PageContent_Cta {
                        buttonText
                        content
                        heading
                        intro
                        linkOrPopup
                        link {
                            ... on WpPage {
                                id
                                uri
                                slug
                            }
                            ... on WpProject {
                                id
                                uri
                                slug
                            }
                        }
                        style
                        fieldGroupName
                    }
                    ... on WpPage_Contentitems_PageContent_ImageTextBlock {
                        maskImage
                        position
                        buttonText
                        content
                        heading
                        link {
                            ... on WpPage {
                                id
                                uri
                                slug
                            }
                            ... on WpProject {
                                id
                                uri
                                slug
                            }
                        }
                        image {
                            sourceUrl
                            altText
                            localFile {
                                childImageSharp {
                                    gatsbyImageData(width: 400, height: 400, quality: 90, layout: FULL_WIDTH)
                                }
                            }
                            mediaItemUrl
                        }
                        position
                        style
                        fieldGroupName
                    }
                    ... on WpPage_Contentitems_PageContent_Quote {
                        author
                        quoteText
                        image {
                            sourceUrl
                            altText
                            localFile {
                                childImageSharp {
                                    gatsbyImageData(width: 400, height: 400, quality: 90, layout: FULL_WIDTH)
                                }
                            }
                            mediaItemUrl
                        }
                        fieldGroupName
                    }
                    ... on WpPage_Contentitems_PageContent_ContentBlock {
                        content
                        fieldGroupName
                        heading
                        subHeading
                    }
                    ... on WpPage_Contentitems_PageContent_ListBlock {
                        fieldGroupName
                        heading
                        intro
                        items {
                            fieldGroupName
                            item
                        }
                    }
                    ... on WpPage_Contentitems_PageContent_Accordion {
                        fieldGroupName
                        heading
                        intro
                        isFaq
                        items {
                            fieldGroupName
                            item
                            itemHeading
                        }
                    }
                    ... on WpPage_Contentitems_PageContent_LogoWall {
                        fieldGroupName
                        heading
                        items {
                            sourceUrl
                            altText
                            localFile {
                                childImageSharp {
                                    gatsbyImageData(width: 180, quality: 90, layout: FIXED)
                                }
                            }
                            mediaItemUrl
                        }
                    }
                    ... on WpPage_Contentitems_PageContent_ContactBlock {
                        fieldGroupName
                        heading
                        intro
                    }
                    ... on WpPage_Contentitems_PageContent_FeaturedItems {
                        fieldGroupName
                        heading
                        intro
                        items {
                            ... on WpPage {
                                id
                                uri
                                slug
                                title
                                featuredImage {
                                    node {
                                        sourceUrl
                                        mediaItemUrl
                                        altText
                                        localFile {
                                            childImageSharp {
                                                gatsbyImageData(width: 300, height: 280, quality: 90, layout: FIXED)
                                            }
                                        }
                                    }
                                }
                            }
                            ... on WpPost {
                                id
                                uri
                                slug
                                title
                                featuredImage {
                                    node {
                                        sourceUrl
                                        mediaItemUrl
                                        altText
                                        localFile {
                                            childImageSharp {
                                                gatsbyImageData(width: 300, height: 280, quality: 90, layout: FIXED)
                                            }
                                        }
                                    }
                                }
                            }
                            ... on WpProject {
                                id
                                uri
                                slug
                                title
                                featuredImage {
                                    node {
                                        sourceUrl
                                        mediaItemUrl
                                        altText
                                        localFile {
                                            childImageSharp {
                                                gatsbyImageData(width: 300, height: 280, quality: 90, layout: FIXED)
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                    ... on WpPage_Contentitems_PageContent_Downloads {
                        fieldGroupName
                        heading
                        intro
                        items {
                            title
                            fieldGroupName
                            file {
                                sourceUrl
                                mediaItemUrl
                            }
                        }
                    }
                    ... on WpPage_Contentitems_PageContent_StandoutImage {
                        fieldGroupName
                        image {
                            mediaItemUrl
                            sourceUrl
                            altText
                            localFile {
                                childImageSharp {
                                    gatsbyImageData(width: 800, height: 800, quality: 90, layout: FULL_WIDTH)
                                }
                            }
                        }
                    }
                    ... on WpPage_Contentitems_PageContent_VideoBlock {
                        fieldGroupName
                        embed
                        heading
                        intro
                    }
                    ... on WpPage_Contentitems_PageContent_Gallery {
                        fieldGroupName
                        heading
                        intro
                        gallery {
                            mediaItemUrl
                            sourceUrl
                            altText
                            localFile {
                                childImageSharp {
                                    #thumbG: gatsbyImageData(width: 340, height: 340, quality: 90, layout: FULL_WIDTH)
                                    thumb: fluid(maxWidth: 340, maxHeight: 340, quality: 90) {
                                        ...GatsbyImageSharpFluid_withWebp
                                    }
                                    full: fluid(maxWidth: 1024) {
                                        ...GatsbyImageSharpFluid_withWebp
                                    }
                                }
                            }
                        }
                    }
                    ... on WpPage_Contentitems_PageContent_SubPages {
                        fieldGroupName
                        heading
                        intro
                    }
                    ... on WpPage_Contentitems_PageContent_InlineBlocks {
                        fieldGroupName
                        heading
                        intro
                        items {
                            heading
                            fieldGroupName
                            image {
                                sourceUrl
                                mediaItemUrl
                                altText
                                localFile {
                                    childImageSharp {
                                        gatsbyImageData(width: 300, height: 280, quality: 90, layout: FIXED)
                                    }
                                }
                            }
                        }
                    }
                    ... on WpPage_Contentitems_PageContent_ImageSlider {
                        fieldGroupName

                        gallery {
                            altText
                            sourceUrl
                            localFile {
                                childImageSharp {
                                    gatsbyImageData(width: 760, height: 590, quality: 90, layout: CONSTRAINED)
                                }
                            }
                        }
                    }
                    ... on WpPage_Contentitems_PageContent_Listing {
                        heading
                        intro
                        items {
                            ... on WpPage {
                                ...PageEntryFragment
                            }
                            ... on WpProject {
                                ...ProjectEntryFragment
                            }
                        }
                        fieldGroupName
                    }
                }
                subHeading
                standoutHeading
                intro
                image {
                    sourceUrl
                    altText
                    localFile {
                        childImageSharp {
                            # gatsbyImageData(width: 1920, height: 640, quality: 90, layout: FULL_WIDTH)
                            fluid(maxWidth: 1920, maxHeight: 640, quality: 90) {
                                ...GatsbyImageSharpFluid_withWebp
                            }
                        }
                    }
                    mediaItemUrl
                }
            }
        }
    }
`;
